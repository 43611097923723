






































































































































































































import { Component, Vue } from "vue-property-decorator";
import BasePage from "@/components/pages/BasePage.vue";
import { namespace } from 'vuex-class';

const Products = namespace("Products");
const GasAccounts = namespace("GasAccounts");
const Auth = namespace("Auth");

@Component({
    components: {
        BasePage
    },
})
export default class HelpAndSupport extends Vue {
    @Products.Getter
    private getSelectedProduct!: string;

    @GasAccounts.Getter
    private getSelectedSiteRefNumber!: string;

    @GasAccounts.Getter
    private getSelectedAccount!: string;

    @Auth.Getter
    private getPortalAuthTokenUrl!: string;

    private watchProductSelection: any;
    private watchAccountSelection: any;
    private watchGasAgreementSelection: any;

    private productText = "gas";

    private siteAddressUrl = "";
    private billingAddressUrl = "";

    private refreshHelpAndSupport(): void {

        const routeName = this.$route.name;

        if (this.getSelectedProduct == "Power") {
            this.productText = "power";
        }
        else {
            this.productText = "gas";
        }
    }


    async mounted() {

        this.refreshHelpAndSupport();

        this.watchProductSelection = this.$store.watch((state, getters) => getters["Products/getSelectedProduct"], (newValue, oldValue) => {
            if (newValue === null || newValue === oldValue) {
                return;
            }

            this.refreshHelpAndSupport();

        });


        this.watchAccountSelection = this.$store.watch((state, getters) => getters["GasAccounts/getSelectedAccount"], (newValue, oldValue) => {
            if (newValue === null || newValue === oldValue) {
                return;
            }

            this.refreshHelpAndSupport();
        });


        this.watchGasAgreementSelection = this.$store.watch((state, getters) => getters["GasAccounts/getSelectedSiteRefNumber"], (newValue, oldValue) => {
            if (newValue === null || newValue === oldValue) {
                return;
            }

            this.refreshHelpAndSupport();
        });

    }
}

